import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import bgImage from "../images/why-choose-bg.png";

const TreePlanting = ({
	heading,
	tagline,
	plantingHeading,
	plantingDesc,
	plantingEvents,
	sponsorHeading,
	sponsorDesc,
	treeCards,
	backgroundColor,
}) => {
	return (
		<section
			className="py-5 py-md-8"
			style={{ ...sectionWrapper(bgImage), backgroundColor: backgroundColor }}
		>
			<Container>
				<Row className="align-items-lg-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-start">
						<span className="text-white">{tagline}</span>
						<h2 className="my-auto text-white pb-2">{heading}</h2>
					</Col>
				</Row>

				<Row className="pt-5 pt-md-7 justify-content-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-center">
						<div className="d-block">
							<div className="position-relative d-inline-block">
								<h3 className="my-auto text-white d-inline-block pb-2 montserrat-black">
									{plantingHeading}
								</h3>
								<hr
									className="bg-secondary mx-auto my-0 mw-100 border-0 text-center"
									style={{ height: "12px", opacity: 1 }}
								/>
							</div>
						</div>

						<div
							dangerouslySetInnerHTML={{
								__html: plantingDesc,
							}}
							className="fw-light text-white text-center mt-3"
						/>
					</Col>
					<Row className="mt-5">
						{plantingEvents.map((item, i) => (
							<Col lg={4} key={i} className="mb-4 mb-md-5 mb-lg-0">
								<div className="border border-white rounded p-4 h-100 d-flex flex-column">
									<p className="text-white m-0 fs-6">
										{item.privatePlantingEventSubHeading}
									</p>
									<span className="text-white fs-5">
										{item.privatePlantingEventPrice}
									</span>
									<h4 className="fs-1 text-white border-bottom border-white py-4 mb-0">
										{item.privatePlantingEventHeading}
									</h4>
									<p className="text-white py-4 mb-0 fs-6 montserrat-semibold">
										{item.privatePlantingEventDescription}
									</p>
									<Button
										as={Link}
										href={item.privatePlantingEventButton.url}
										size="lg"
										className="px-4 py-3 mt-auto montserrat-bold fs-6 w-100"
										target={item.privatePlantingEventButton.target}
										variant="secondary"
									>
										{item.privatePlantingEventButton.title}
									</Button>
								</div>
							</Col>
						))}
					</Row>
				</Row>
				<Row className="pt-5 pt-md-7 justify-content-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-center">
						<div className="d-block">
							<div className="position-relative d-inline-block">
								<h3 className="my-auto text-white pb-2 montserrat-black">
									{sponsorHeading}
								</h3>
								<hr
									className="bg-secondary mw-100 mx-auto my-0 border-0 text-center"
									style={{ height: "12px", opacity: 1 }}
								/>
							</div>
						</div>

						<div
							dangerouslySetInnerHTML={{
								__html: sponsorDesc,
							}}
							className="fw-light text-white text-center mt-3"
						/>
					</Col>
					<Row className="mt-5">
						{treeCards.map((item, i) => (
							<Col lg={4} key={i}>
								<div className="py-4 px-6 h-100 d-flex flex-column">
									<img
										src={item.sponsorTreeCardIcon.node?.sourceUrl}
										alt={item.sponsorTreeCardIcon.node?.altText}
										style={{ width: "32px", height: "32px" }}
									/>
									<h4 className="fs-3 text-white mt-4 mb-1 montserrat-black">
										{item.sponsorTreeCardHeading}
									</h4>
									<div
										className="text-white fs-5 sponsor-tree-price"
										dangerouslySetInnerHTML={{
											__html: item.sponsorTreeCardPrice,
										}}
									></div>
									<p className="text-white mb-4 fs-6 montserrat-medium">
										{item.sponsorTreeCardDescription}
									</p>
									<Button
										as={Link}
										href={item.sponsorTreeCardButton.url}
										size="lg"
										className="px-3 py-2 mt-auto montserrat-bold fs-6 border-white text-white"
										style={{ width: "fit-content" }}
										target={item.sponsorTreeCardButton.target}
									>
										{item.sponsorTreeCardButton.title}
									</Button>
								</div>
							</Col>
						))}
					</Row>
				</Row>
			</Container>
		</section>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "top right",
	backgroundSize: "40%",
});

export default TreePlanting;
